











import { defineComponent } from "@vue/composition-api"
export default defineComponent({
  props: {
    bordered: Boolean,
    height: [String, Number],
    width: [String, Number],
  },
})
